<template>
  <div>
    <label>User Assignment</label>
    <multiselect
      id="msUserAssignment"
      placeholder="Select Users"
      open-direction="bottom"
      label="userName"
      track-by="id"
      :options="usersAssignment"
      :value="selectedUsers"
      :disabled="disabled"
      @input="setSelectedOrderTemplateUserAssignment"
      :multiple="true"
      :searchable="true"
      :loading="isSearching"
      :clear-on-select="false"
      :close-on-select="false"
      :max-height="600"
      :show-no-results="true"
      :hide-selected="false"
    >
      <template slot="clear">
        <div
          class="multiselect__clear"
          v-if="!!selectedUsers.length"
          @mousedown.prevent.stop="clearSelected()"
        ></div>
      </template>
			<template slot="tag" slot-scope="props">
				<div class="multiselect__tag" :style="props.option.isDeleted ? 'background: #FF0000' : 'background: #114c8e'">
					<span>{{ props.option.email }}</span>
					<i tabindex="1" class="multiselect__tag-icon" @click="removeFromSelection(props.option)"></i>
				</div>
			</template>
      <span slot="noResult">
        No users found. Consider changing the search query.
      </span>
    </multiselect>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import { RepositoryFactory } from "@/services/RepositoryFactory";

const UserRepository = RepositoryFactory.get("users");

export default {
  props: ["customerId","userId","id", "disabled"],

  components: {
    Multiselect
  },

  data() {
    return {
      isSearching: false,
      isDirty: true,
      usersAssignment: []
    };
  },

  async mounted() {
    if (!this.isDirty) { return; }
		this.isSearching = true;

		try {
			const response = await UserRepository.get({ id: this.customerId }, true);
			this.isDirty = false;
			this.usersAssignment = response.data.results;
			this.autoSelectUsersById(response.data.results);
		} catch (err) {
			console.error(err);
		} finally {
			this.isSearching = false;
		}
  },

  methods: {
    ...mapActions("newOrderTemplate", [
      "setSelectedOrderTemplateUserAssignment",
      "clearSelectedOrderTemplateUserAssignment"]),

    clearSelected() {
      this.clearSelectedOrderTemplateUserAssignment()
    },

    autoSelectUsersById(users){
      if(this.selectedUsersId && this.selectedUsersId.length){
        const selectedUsers = users.filter(user => this.selectedUsersId.includes(user.id));

    		this.setSelectedOrderTemplateUserAssignment(selectedUsers);
      }
    },

		removeFromSelection(user){
			const selectedUsers = this.selectedUsers.filter(item => item.id !== user.id);

			this.setSelectedOrderTemplateUserAssignment(selectedUsers);
		}
  },

  computed: {
    ...mapState("newOrderTemplate", ["selectedUsers", "selectedUsersId"])
  },

  watch: {
    disabled(val){
      if(val){
        this.clearSelected();
      }
    }
  }
};
</script>
